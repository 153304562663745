// Core packages
import React from "react";

// Third party packages
import { GoogleMap, LoadScript } from "@react-google-maps/api";

// Custom packages

/**
 * Script start
 */
const Mappa = () => {
  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const googleMapsApiKey = "AIzaSyCjP8loXcEgyCyRRJ0CVfnZhNl0px7RjX4";
  const center = {
    lat: 46.0898545,
    lng: 9.3109951,
  };

  return (
    <LoadScript googleMapsApiKey={googleMapsApiKey}>
      <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
        {/* Child components, such as markers, info windows, etc. */}
        <></>
      </GoogleMap>
    </LoadScript>
  );
};
export default Mappa;
