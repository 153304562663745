// Core packages
import React from "react";

// Third party packages
import { connect } from "react-redux";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import { Helmet } from "react-helmet";

// Custom packages
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import SliderDot from "../components/SliderDot";
import pescatori from "../assets/pescatori.jpg";
import conte from "../assets/conte.jpg";
import cavalieri from "../assets/cavalieri.jpg";
import Biglietto from "../components/Biglietto";
import slider from "../assets/homepage_blocco1-fotoA.jpg";
import logo from "../assets/icone/ar-button.png";
import pin from "../assets/icone/icon-pin.png";
import header from "../assets/header/homepage.jpg";
import BarPercorsi from "../components/BarPercorsi";

/**
 * Script start
 */
const Homepage = (props) => {
  const { curr_lang, t } = props;
  console.log("curr_lang", curr_lang);

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.homepage.meta.title")}</title>
        <meta
          name="description"
          content={t("pages.homepage.meta.description")}
        />
      </Helmet>
      <Container fluid className="homepage">
        <Row
          className="header"
          style={{
            backgroundImage: "url(" + header + ")",
            backgroundRepeat: "none",
          }}
        >
          <Menu></Menu>
          <Col
            md={12}
            style={{
              borderTop: "solid 1px #ffffff",
              borderBottom: "solid 1px #ffffff",
              height: "10px",
            }}
          ></Col>
          <Col
            md={12}
            style={{
              borderTop: "solid 1px #ffffff",
              borderBottom: "solid 1px #ffffff",
              height: "20px",
            }}
          ></Col>
          <Col md={12}>
            <p className="text-italic italic-box content-center">
              {t("pages.homepage.herobanner.pretitle")}
            </p>
            <p className="text-bold title">
              {t("pages.homepage.herobanner.title")}
            </p>
          </Col>
          <p className="logo-header content-center">
            <a href={`/${curr_lang}/ar`}>
              <img src={logo} alt="Corenno Plinio" style={{ width: "180px" }} />
            </a>
          </p>
        </Row>

        <Row>
          <Col className="text-box text-left" md={6}>
            <p className="text-italic italic-title">
              {t("pages.homepage.firstRow.pretitle")}
            </p>
            <h1 className="text-bold">{t("pages.homepage.firstRow.title")}</h1>
            <p className="text-color">
              {t("pages.homepage.firstRow.firstParagraph")}
              <br />
              <br />
              {t("pages.homepage.firstRow.secondParagraph")}
              <br />
            </p>
            <a className="text-link" href={"/" + props.curr_lang + "/borgo/"}>
              {t("pages.homepage.firstRow.cta")}
            </a>
          </Col>
          <Col md={6} className="no-padding">
            <img
              src={slider}
              alt="Scopri il bordo di Corenno Plino"
              style={{ width: "100%" }}
            />
          </Col>
        </Row>
        <Row className="slider-row">
          <Col md={12} className="no-padding">
            <SliderDot></SliderDot>
          </Col>
        </Row>
        <Row>
          <Col md={12} className=" ar-row text-center">
            <img src={pin} alt="Pin" style={{ width: "12px" }} />
            <a className="text-link" href={`/${curr_lang}/luoghi`}>
              {t("pages.homepage.discoverAllPlaces")}
            </a>
          </Col>
        </Row>
        <BarPercorsi></BarPercorsi>
        <Row>
          <Col md={4} className="no-padding">
            <Card className="homepage">
              <Card.Img variant="top" src={conte} />
              <Card.Body style={{ backgroundColor: "#418c41" }}>
                <Card.Title className="text-bold subtitle">
                  {t("pages.homepage.secondRow.firstCard.title")}
                </Card.Title>
                <Card.Text>
                  {t("pages.homepage.secondRow.firstCard.text")}
                </Card.Text>
                <a href={"/" + curr_lang + "/percorsi-tematici#row-conte"}>
                  <Button variant="primary transparent">
                    {t("pages.homepage.secondRow.firstCard.cta")}
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="no-padding">
            <Card>
              <Card.Img variant="top" src={cavalieri} />
              <Card.Body style={{ backgroundColor: "#cd2c27" }}>
                <Card.Title className="text-bold subtitle">
                  {t("pages.homepage.secondRow.secondCard.title")}
                </Card.Title>
                <Card.Text>
                  {t("pages.homepage.secondRow.secondCard.text")}
                </Card.Text>
                <a href={"/" + curr_lang + "/percorsi-tematici#row-cavalieri"}>
                  <Button variant="primary transparent">
                    {t("pages.homepage.secondRow.secondCard.cta")}
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4} className="no-padding">
            <Card>
              <Card.Img variant="top" src={pescatori} />
              <Card.Body style={{ backgroundColor: "#2e92ba" }}>
                <Card.Title className="text-bold subtitle">
                  {t("pages.homepage.secondRow.thirdCard.title")}
                </Card.Title>
                <Card.Text>
                  {t("pages.homepage.secondRow.thirdCard.text")}
                </Card.Text>
                <a href={"/" + curr_lang + "/percorsi-tematici#row-pescatori"}>
                  <Button variant="primary transparent">
                    {t("pages.homepage.secondRow.thirdCard.cta")}
                  </Button>
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Biglietto></Biglietto>
        <Row className="cartolina">
          <div className="all-center ">
            <p className="white-text text-bold italic-title">
              {t("pages.homepage.thirdRow.title")}
            </p>
            <p className="white-text text-italic big ">
              {t("pages.homepage.thirdRow.subtitle")}
            </p>
          </div>
        </Row>

        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Homepage);
