// Core packages
import React from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import ar_it from "../assets/banner-ar_it.png";
import ticket_it from "../assets/ticket_it.png";
import ar_en from "../assets/banner-ar_en.png";
import ticket_en from "../assets/ticket_en.png";

/**
 * Script start
 */
const Biglietto = (props) => {
  const { curr_lang } = props;

  return (
    <Container fluid className="biglietto">
      <Row style={{ marginTop: "90px", marginBottom: "90px" }}>
        <Col md={6} style={{ textAlign: "right" }}>
          <a href={`/${curr_lang}/ar`}>
            <img
              src={curr_lang === "it" ? ar_it : ar_en}
              alt="Realtà aumentata"
              style={{ maxWidth: "575px", width: "100%" }}
            />
          </a>
        </Col>
        <Col md={6} style={{ textAlign: "left" }}>
          <a href={`/${curr_lang}/info`}>
            <img
              src={curr_lang === "it" ? ticket_it : ticket_en}
              alt="Biglietti Corenno Pilinio"
              style={{ maxWidth: "575px", width: "100%" }}
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Biglietto);
