// Core packages
import React from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";

// Custom packages

/**
 * Script start
 */
const Percorso = (props) => {
  return (
    <Container>
      {props.allineamento === "dispari" ? (
        <Row className="tappa">
          <Col md={2}></Col>
          <Col md={5}>
            <img src={props.img} alt={props.tappa} style={{ width: "100%" }} />
          </Col>
          <Col md={5} className="dettaglio-luoghi">
            <h2 className="text-bold">{props.tappa}</h2>
            <p>{props.descrizione}</p>
            <p>
              <a className="text-link" href={props.urw}>
                Scopri di più
              </a>
            </p>
          </Col>
        </Row>
      ) : (
        <Row className="tappa pari">
          <Col md={5} className="dettaglio-luoghi">
            <h2 className="text-bold">{props.tappa}</h2>
            <p>{props.descrizione}</p>
            <p>
              <a className="text-link" href={props.urw}>
                Scopri di più
              </a>
            </p>
          </Col>
          <Col md={5}>
            <img
              src={props.img}
              alt="Realtà aumentata Corenno Plinio"
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={2}></Col>
        </Row>
      )}
    </Container>
  );
};

export default Percorso;
