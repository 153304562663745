// Core packages
import React, { useState } from "react";
import { useEffect } from "react";

// Third party packages
import { connect } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";

// Custom packages
import Percorso from "../components/Percorso";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import pcavalieri from "../assets/percorsi/percorso-cavalieri.jpg";
import pconte from "../assets/percorsi/percorso-conte.jpg";
import ppescatori from "../assets/percorsi/percorso-pescatori.jpg";
import header from "../assets/header/mappa_percorsi.jpg";
import iconcircle from "../assets/icone/icon-close-circle.png";
import Biglietto from "../components/Biglietto";

/**
 * Script start
 */

const Percorsi = (props) => {
  const { curr_lang, luoghi, t } = props;
  const [pescatori, setPescatori] = useState([]);
  const [cavalieri, setCavalieri] = useState([]);
  const [conti, setConti] = useState([]);
  const [pescatoriVisibility, setPescatoriVisibility] = useState(false);
  const [cavalieriVisibility, setCavalieriVisibility] = useState(false);
  const [contiVisibility, setContiVisibility] = useState(false);

  const ordinamento = (luoghi, tipo) => {
    const sortedItems = [];
    for (const luogo of luoghi) {
      for (const percorso of luogo.percorso) {
        if (percorso.nome === tipo) {
          sortedItems[percorso.pos - 1] = luogo;
        }
      }
    }

    return sortedItems;
  };

  useEffect(() => {
    const newPescatori = [];
    const newConti = [];
    const newCavalieri = [];

    for (const luogo of luoghi) {
      for (const percorso of luogo.percorso) {
        if (percorso.nome === "pescatore") {
          newPescatori.push(luogo);
        }
        if (percorso.nome === "conte") {
          newConti.push(luogo);
        }
        if (percorso.nome === "cavaliere") {
          newCavalieri.push(luogo);
        }
      }
    }

    setPescatori(ordinamento(newPescatori, "pescatore"));
    setConti(ordinamento(newConti, "conte"));
    setCavalieri(ordinamento(newCavalieri, "cavaliere"));
  }, [luoghi]);

  return (
    <Container fluid>
      <Menu></Menu>

      <Row className="gray-background">
        <Col md={12} className="header">
          <img src={header} alt="Percorsi tematici Corenno Plinio" />
        </Col>
      </Row>

      <Row className="gray-background" id="row-conte">
        <Col md={8} className="text-center content-center ar-row ">
          <p className="text-italic italic-title">
            {t("pages.luogo.firstRow.pretitle")}
          </p>
          <h1 className="text-bold">{t("pages.luogo.firstRow.title")}</h1>
          <p>{t("pages.luogo.firstRow.text")}</p>
        </Col>
      </Row>

      <Row>
        <Col md={3} style={{ padding: "0px" }}>
          <img
            className="cardImage"
            src={pconte}
            alt="Passaggiata del Conte a Corenno Plinio"
          />
        </Col>
        <Col
          md={6}
          className="text-left box-percorso white-text green-background"
        >
          <h2 className="text-bold">
            {t("pages.luogo.secondRow.firstCard.title")}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t("pages.luogo.secondRow.firstCard.text"),
            }}
          />
          <br></br>
          <Button
            variant="primary transparent"
            id="button-conte"
            onClick={(e) => {
              e.target.setAttribute("disabled", "disabled");
              setContiVisibility(true);
            }}
          >
            {t("pages.luogo.secondRow.firstCard.cta")}
          </Button>
        </Col>
        <Col md={3} className="descrizione text-left green-background">
          <p>{t("general.itineraryDistance")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.firstCard.distance")}
          </p>
          <p>{t("general.itineraryDuradion")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.firstCard.duration")}
          </p>
          <p>{t("general.itineraryPoi")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.firstCard.poiNumbers")}
          </p>
        </Col>
      </Row>

      {contiVisibility && (
        <div id="luoghi-conte">
          <Container>
            <p className="text-right close-area">
              <a
                className="text-link"
                href="#luoghi-conte"
                onClick={() => {
                  document.getElementById("button-conte").disabled = false;
                  setContiVisibility(false);
                }}
              >
                {t("general.close")}
                <img className="icon-circle" alt="Chiudi" src={iconcircle} />
              </a>
            </p>
          </Container>
          {conti.map((conte, index) => (
            <Percorso
              key={conte.id}
              img={
                process.env.PUBLIC_URL +
                "/assets/img/luoghi/" +
                conte.urw +
                "/cover/" +
                conte.img
              }
              urw={"/" + curr_lang + "/luogo/" + conte.urw}
              tappa={conte.tappa}
              descrizione={conte.breve}
              allineamento={(index + 1) % 2 === 0 ? "pari" : "dispari"}
            ></Percorso>
          ))}
        </div>
      )}

      <Row id="row-cavalieri" className="order-row">
        <Col
          md={6}
          className="box-percorso text-left white-text red-background"
        >
          <h2 className="text-bold">
            {t("pages.luogo.secondRow.secondCard.title")}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t("pages.luogo.secondRow.secondCard.text"),
            }}
          />
          <br></br>
          <Button
            variant="primary transparent"
            id="button-cavalieri"
            onClick={(e) => {
              e.target.setAttribute("disabled", "disabled");
              setCavalieriVisibility(true);
            }}
          >
            {t("pages.luogo.secondRow.secondCard.cta")}
          </Button>
        </Col>
        <Col md={3} className="descrizione text-left red-background">
          <p>{t("general.itineraryDistance")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.secondCard.distance")}
          </p>
          <p>{t("general.itineraryDuradion")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.secondCard.duration")}
          </p>
          <p>{t("general.itineraryPoi")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.secondCard.poiNumbers")}
          </p>
        </Col>
        <Col md={3} style={{ padding: "0px" }}>
          <img
            className="cardImage"
            src={pcavalieri}
            alt="Saliscendi dei Cavalieri a Corenno Plinio"
          />
        </Col>
      </Row>

      {cavalieriVisibility && (
        <div id="luoghi-cavalieri">
          <Container>
            <p className="text-right close-area">
              <a
                className="text-link"
                href="#luoghi-cavalieri"
                onClick={() => {
                  document.getElementById("button-cavalieri").disabled = false;
                  setCavalieriVisibility(false);
                }}
              >
                {t("general.close")}
                <img className="icon-circle" alt="Chiudi" src={iconcircle} />
              </a>
            </p>
          </Container>
          {cavalieri.map((cavalieri, index) => (
            <Percorso
              key={cavalieri.id}
              img={
                process.env.PUBLIC_URL +
                "/assets/img/luoghi/" +
                cavalieri.urw +
                "/cover/" +
                cavalieri.img
              }
              urw={"/" + curr_lang + "/luogo/" + cavalieri.urw}
              tappa={cavalieri.tappa}
              descrizione={cavalieri.breve}
              allineamento={(index + 1) % 2 === 0 ? "pari" : "dispari"}
            />
          ))}
        </div>
      )}

      <Row id="row-pescatori">
        <Col md={3} style={{ padding: "0px" }}>
          <img
            className="cardImage"
            src={ppescatori}
            alt="Traversata dei Pescatori a Corenno Plinio"
          />
        </Col>
        <Col
          md={6}
          className="box-percorso text-left white-text blue-background"
        >
          <h2 className="text-bold">
            {t("pages.luogo.secondRow.thirdCard.title")}
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: t("pages.luogo.secondRow.thirdCard.text"),
            }}
          />
          <br></br>
          <Button
            variant="primary transparent"
            id="button-pescatori"
            onClick={(e) => {
              e.target.setAttribute("disabled", "disabled");
              setPescatoriVisibility(true);
            }}
          >
            {t("pages.luogo.secondRow.thirdCard.cta")}
          </Button>
        </Col>
        <Col md={3} className="descrizione text-left blue-background">
          <p>{t("general.itineraryDistance")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.thirdCard.distance")}
          </p>
          <p>{t("general.itineraryDuradion")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.thirdCard.duration")}
          </p>
          <p>{t("general.itineraryPoi")}</p>
          <p className="text-semibold">
            {t("pages.luogo.secondRow.thirdCard.poiNumbers")}
          </p>
        </Col>
      </Row>

      {pescatoriVisibility && (
        <div id="luoghi-pescatori">
          <Container>
            <p className="text-right close-area">
              <a
                className="text-link"
                href="#luoghi-pescatori"
                onClick={() => {
                  document.getElementById("button-pescatori").disabled = false;
                  setPescatoriVisibility(false);
                }}
              >
                {t("general.close")}
                <img className="icon-circle" alt="Chiudi" src={iconcircle} />
              </a>
            </p>
          </Container>
          {pescatori.map((pescatori, index) => {
            return (
              <Percorso
                key={pescatori.id}
                img={
                  process.env.PUBLIC_URL +
                  "/assets/img/luoghi/" +
                  pescatori.urw +
                  "/cover/" +
                  pescatori.img
                }
                urw={"/" + curr_lang + "/luogo/" + pescatori.urw}
                tappa={pescatori.tappa}
                descrizione={pescatori.breve}
                allineamento={(index + 1) % 2 === 0 ? "pari" : "dispari"}
              ></Percorso>
            );
          })}
        </div>
      )}

      <Biglietto></Biglietto>
      <Footer />
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Percorsi);
