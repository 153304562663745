// Core packages
import React, { useEffect, useState } from "react";

// Third party packages
import { connect } from "react-redux";

// Custom packages
import arrowup from "../assets/icone/up.png";
import arrowdown from "../assets/icone/down.png";
import check from "../assets/icone/check.png";
import { addFilter } from "../store/actions/index";
import catIt from "../data/it/categorie.json";
import catEn from "../data/en/categorie.json";

/**
 * Script start
 */
const Filtro = (props) => {
  const { addFilter, filtri_attivi, curr_lang } = props;
  const [open, setOpen] = useState(false);
  const [cat, setCat] = useState(curr_lang === "en" ? catEn : catIt);

  useEffect(() => {
    if (curr_lang === "en") {
      setCat(catEn);
    } else {
      setCat(catIt);
    }
  }, [curr_lang]);

  return (
    <div id="filtro" className={open ? "open-filter" : ""}>
      {open ? (
        <div>
          <p className="text-regular " onClick={() => setOpen(false)}>
            Filtra per itinerario
            <img className="arrow-filtro" src={arrowdown} alt="Freccia" />
          </p>
          {cat.map((element, index) => (
            <p
              key={index}
              className="text-regular filtro-list"
              style={{ color: element.color }}
              onClick={() => addFilter(element.slug)}
            >
              <span>
                <img
                  className="icon-filter"
                  alt={element.nome}
                  src={`${process.env.PUBLIC_URL}/assets/img/categorie/${element.path}icona/${element.img}`}
                />
                {element.nome}
              </span>
              {filtri_attivi.some(
                (p) => p.nome === element.slug && p.stato
              ) && <img className="check" src={check} alt="Check" />}
            </p>
          ))}
        </div>
      ) : (
        <p className="text-regular" onClick={() => setOpen(true)}>
          Filtra per itinerario
          <img className="arrow-filtro" src={arrowup} alt="Freccia" />
        </p>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  addFilter: (sl) => dispatch(addFilter(sl)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Filtro);
