import React from "react";
import Menu from "../components/Menu";
// Core packages

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// Custom packages
import header from "../assets/header/luoghi.jpg";
import BarPercorsi from "../components/BarPercorsi";
import Footer from "../components/Footer";
import Portfolio from "../components/Portfolio";
import Biglietto from "../components/Biglietto";

/**
 * Script start
 */
const Luoghi = (props) => {
  const { t } = props;
  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.luoghi.meta.title")}</title>
        <meta name="description" content={t("pages.luoghi.meta.description")} />
      </Helmet>
      <Container fluid className="page-percorsi">
        <Menu></Menu>
        <Row>
          <Col md={12} className="header">
            <img src={header} alt="Luoghi di Corenno Plinio" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="text-center content-center">
            <p className="text-italic italic-title">
              {t("pages.luoghi.firstRow.pretitle")}
            </p>
            <h1 className="text-bold">{t("pages.luoghi.firstRow.title")}</h1>
            <p>{t("pages.luoghi.firstRow.text")}</p>
          </Col>
        </Row>
        <Portfolio></Portfolio>
        <BarPercorsi></BarPercorsi>
        <Biglietto></Biglietto>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Luoghi);
