// Core packages
import React from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import FooterCSoon from "../components/FooterCSoon";
import header from "../assets/header/coming-soon.jpg";
import logo from "../assets/logo.png";

/**
 * Script start
 */
const Costruzione = (props) => {
  const { curr_lang, t } = props;
  console.log("curr_lang", curr_lang);

  return (
    <Container fluid className="homepage coming-soon">
      <Row
        className="header coming-soon"
        style={{
          backgroundImage: "url(" + header + ")",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Col md={12} className="no-padding">
          <Col md={12}>
            <img src={logo} alt="Corenno Plinio" className="logo-top" />
          </Col>
          <Col
            md={12}
            style={{
              borderTop: "solid 1px #ffffff",
              borderBottom: "solid 1px #ffffff",
              height: "10px",
            }}
          />
          <Col
            md={12}
            style={{
              borderTop: "solid 1px #ffffff",
              borderBottom: "solid 1px #ffffff",
              height: "20px",
            }}
          />
        </Col>
        <Col md={12}>
          <p className="text-italic italic-box content-center">
            {t("pages.homepage.herobanner.pretitle")}
          </p>
          <p className="text-bold title">
            {t("pages.homepage.herobanner.title")}
          </p>
        </Col>
      </Row>

      <FooterCSoon></FooterCSoon>
    </Container>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Costruzione);
