// Core packages
import React, { useEffect } from "react";

// Third party packages
import { connect } from "react-redux";
import { Nav, Navbar, Row, Col } from "react-bootstrap";

// Custom packages
import logo from "../assets/logo.png";
import fb from "../assets/facebook.png";
import insta from "../assets/instagram.png";
import iconclose from "../assets/icone/icon-close.png";
import { setEn, setIt } from "../store/actions/index";
import enflag from "../assets/icone/eng-black.png";
import itaflag from "../assets/icone/ita-black.png";
import enover from "../assets/icone/en-color.jpg";
import iconaar from "../assets/icone/icona-ar.png";
import itaover from "../assets/icone/ita-color.jpg";

/**
 * Script start
 */
const Menu = (props) => {
  const { curr_lang, t } = props;

  useEffect(() => {
    document
      .getElementsByClassName("navbar-toggler-icon")[0]
      .addEventListener("click", handleMenuClick);
    window.addEventListener("resize", displayWindowSize);
  }, []);

  const handleMenuClick = () => {
    document.getElementsByClassName("navbar-collapse")[0].classList.add("show");
    document.getElementsByClassName("navbar-brand")[0].style.display = "none";
    document.getElementsByClassName("navbar-toggler-icon")[0].style.display =
      "none";
    document.getElementsByClassName("icon-close")[0].style.display = "block";
    document.getElementsByClassName("navbar")[0].classList.add("resp-open");
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
  };

  const handleCloseClick = () => {
    document.getElementsByClassName("navbar-brand")[0].style.display = "block";
    document.getElementsByClassName("navbar-toggler-icon")[0].style.display =
      "block";
    document.getElementsByClassName("icon-close")[0].style.display = "none";
    document.getElementsByClassName("navbar")[0].classList.remove("resp-open");
    document.getElementsByTagName("body")[0].style.overflow = "auto";
    document
      .getElementsByClassName("navbar-collapse")[0]
      .classList.remove("show");
  };

  const displayWindowSize = () => {
    if (
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) > 992
    ) {
      document.getElementsByClassName("navbar-brand")[0].style.display =
        "block";
      document.getElementsByClassName("navbar-toggler-icon")[0].style.display =
        "none";
      document.getElementsByClassName("icon-close")[0].style.display = "none";
      document
        .getElementsByClassName("navbar")[0]
        .classList.remove("resp-open");
      document.getElementsByTagName("body")[0].style.overflow = "auto";
      document
        .getElementsByClassName("navbar-collapse")[0]
        .classList.remove("show");
    }

    if (
      Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      ) < 992 &&
      document.getElementsByClassName("navbar-brand")[0].style.display ===
        "block"
    ) {
      document.getElementsByClassName("navbar-toggler-icon")[0].style.display =
        "block";
    }
  };

  return (
    <Row className="menu">
      <Col md={12} style={{ padding: "0px" }}>
        <Navbar collapseOnSelect expand="lg">
          <Navbar.Brand href={`/${curr_lang}/`}>
            <img
              alt="Corenno Plinio"
              src={logo}
              style={{ maxWidth: "259px" }}
            />
          </Navbar.Brand>
          <span className="navbar-toggler-icon"></span>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav activeKey="/home">
              <span className="icon-close">
                <img
                  alt="Chiudi"
                  onClick={() => handleCloseClick()}
                  src={iconclose}
                />
              </span>
              <Nav.Item>
                <Nav.Link href={`/${curr_lang}/borgo`}>
                  {t("components.menu.firstLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={`/${curr_lang}/luoghi`}>
                  {t("components.menu.secondLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={`/${curr_lang}/percorsi-tematici`}>
                  {t("components.menu.thirdLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="hide">
                <Nav.Link href={`/${curr_lang}/ar`}>
                  {t("components.menu.fourthLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="ar-link content-center">
                <img
                  src={iconaar}
                  alt="Realtà aumentata"
                  style={{ width: "42px" }}
                />
                <Nav.Link href={`/${curr_lang}/ar`}>
                  {t("components.menu.fourthLinkExtended")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={`/${curr_lang}/info`}>
                  {t("components.menu.fifthLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href={`/${curr_lang}/contatti`}>
                  {t("components.menu.sixthLink")}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="hide">
                <Nav.Link
                  className="social-icon"
                  style={{ marginLeft: "20px" }}
                  href={`https://www.facebook.com/ufficioturisticodervio`}
                  target="_blank"
                >
                  <img src={fb} alt="Facebook" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="hide">
                <Nav.Link
                  className="social-icon"
                  href={`https://www.facebook.com/ufficioturisticodervio`}
                  target="_blank"
                >
                  <img src={insta} alt="Instagram" />
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="hide-992">
                <Nav.Link
                  className="social-icon"
                  href={`https://www.facebook.com/ufficioturisticodervio`}
                  target="_blank"
                >
                  <img src={insta} alt="Instagram" />
                </Nav.Link>
                <Nav.Link
                  className="social-icon"
                  style={{ marginLeft: "30px" }}
                  href={`https://www.facebook.com/ufficioturisticodervio`}
                  target="_blank"
                >
                  <img src={fb} alt="Facebook" />
                </Nav.Link>
              </Nav.Item>
              <p className="hide-992 line content-center"></p>
              {curr_lang === "it" ? (
                <Nav.Item>
                  <Nav.Link className="language">
                    EN
                    <img
                      src={enflag}
                      alt="Bandiera ENG"
                      onMouseEnter={(e) => (e.target.src = enover)}
                      onMouseLeave={(e) => (e.target.src = enflag)}
                      onClick={() => props.setEn()}
                    />
                  </Nav.Link>
                </Nav.Item>
              ) : (
                <Nav.Item>
                  <Nav.Link className="language">
                    IT
                    <img
                      src={itaflag}
                      alt="Bandiera ITA"
                      onMouseEnter={(e) => (e.target.src = itaover)}
                      onMouseLeave={(e) => (e.target.src = itaflag)}
                      onClick={() => props.setIt()}
                    />
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  setEn: () => dispatch(setEn()),
  setIt: () => dispatch(setIt()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
