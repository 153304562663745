// Core packages
import React from "react";

// Third party packages
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Navigate } from "react-router-dom";

// Custom packages
import Menu from "../components/Menu";
import header from "../assets/header/ar.jpg";
import Footer from "../components/Footer";
import Biglietto from "../components/Biglietto";
import iconaar from "../assets/icone/icona-ar.png";
import scansione from "../assets/ar/scansione.png";
import cerca_poi from "../assets/ar/cerca-poi.png";
import qrcodeImage from "../assets/ar/Corenno_AR_qr.jpg";
import iconaqr from "../assets/icone/qr-code.png";
import iconacerca from "../assets/icone/icona-cerca.png";
import iconascopri from "../assets/icone/scopri-poi.png";
import iconainquadra from "../assets/icone/icona-inquadra.png";
import scopri from "../assets/ar/scopri.png";
import inquadratura from "../assets/ar/inquadratura.png";
import percorsi from "../assets/ar/percorsi.png";
import illustrazione from "../assets/ar/illustrazione.jpg";
import arcavalieri from "../assets/percorsi/ar-cavalieri.jpg";
import arconte from "../assets/percorsi/ar-conte.jpg";
import arpescatori from "../assets/percorsi/ar-pescatori.jpg";
// import Arapp from "../components/Ar-app";

/**
 * Script start
 */
const Ar = (props) => {
  const { t, curr_lang } = props;

  const isMobile =
    Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    ) < 600;
  if (isMobile) {
    return <Navigate to={`/${curr_lang}/ar-app`} />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.ar.meta.title")}</title>
        <meta name="description" content={t("pages.ar.meta.description")} />
      </Helmet>
      {/* {isMobile && <Arapp />} */}
      {!isMobile && (
        <React.Fragment>
          <Container fluid className="ar-page">
            <Menu></Menu>
            <Row>
              <Col md={12} className="header">
                <img src={header} alt="Realtà aumentata Corenno Plinio" />
              </Col>
            </Row>
            <Row className="ar-row ">
              <Col md={8} className="text-center content-center">
                <p className="text-italic italic-title">
                  {t("pages.ar.firstRow.pretitle")}
                </p>
                <h1 className="text-bold">{t("pages.ar.firstRow.title")}</h1>
                <p>{t("pages.ar.firstRow.text")}</p>
              </Col>
            </Row>
            <Row className="ar-row">
              <Col className="no-padding" md={8}>
                <img
                  src={illustrazione}
                  alt="Scansiona QRCode"
                  style={{
                    maxWidth: "1280px",
                    height: "100%",
                    position: "relative",
                    display: "inline-block",
                    left: "50%",
                    transform: "translate(-50%)",
                  }}
                />
              </Col>
              <Col md={4} className="ar-box">
                <img src={iconaar} alt="QR Code" style={{ width: "91px" }} />
                <p className="text-bold white-text">
                  <br />
                  {t("pages.ar.secondRow.title")}
                </p>
                <p className="white-text">{t("pages.ar.secondRow.text")}</p>
                <img
                  src={qrcodeImage}
                  alt="QRCode"
                  style={{ padding: "10px 0 0 0", width: "270px" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md={8} className="text-center content-center">
                <p className="text-bold title">
                  {t("pages.ar.thirdRow.title")}
                </p>
                <p>{t("pages.ar.thirdRow.text")}</p>
              </Col>
            </Row>
            <Container>
              <Row className="ar-row">
                <Col md={4}>
                  <img
                    src={scansione}
                    alt="Telefono scansione qrcode"
                    style={{ width: "284px" }}
                  />
                </Col>
                <Col md={8} className="text-left box">
                  <img
                    src={iconaqr}
                    alt="QRCode icona"
                    style={{ width: "46px" }}
                  />
                  <p className="text-bold subtitle">
                    {t("pages.ar.fourthRow.title")}
                  </p>
                  <p>{t("pages.ar.fourthRow.text")}</p>
                </Col>
              </Row>
              <Row className="ar-row">
                <Col md={8} className="text-left box">
                  <img
                    src={iconacerca}
                    alt="Icona telefono"
                    style={{ width: "46px" }}
                  />
                  <p className="text-bold subtitle">
                    {t("pages.ar.fifthRow.title")}
                  </p>
                  <p>{t("pages.ar.fifthRow.text")}</p>
                </Col>
                <Col md={4}>
                  <img
                    src={cerca_poi}
                    style={{ width: "284px" }}
                    alt="Find POI"
                  />
                </Col>
              </Row>
              <Row className="ar-row">
                <Col md={4}>
                  <img
                    src={inquadratura}
                    style={{ width: "284px" }}
                    alt="Icona poi QRCode"
                  />
                </Col>
                <Col md={8} className="text-left box">
                  <img
                    src={iconainquadra}
                    style={{ width: "42px" }}
                    alt="Icona qr poi"
                  />
                  <p className="text-bold subtitle">
                    {t("pages.ar.sixthRow.title")}
                  </p>
                  <p>{t("pages.ar.sixthRow.text")}</p>
                </Col>
              </Row>
              <Row className="ar-row">
                <Col md={8} className="text-left box">
                  <img
                    src={iconascopri}
                    style={{ width: "48px" }}
                    alt="Icona storia"
                  />
                  <p className="text-bold subtitle">
                    {t("pages.ar.seventhRow.title")}
                  </p>
                  <p>{t("pages.ar.seventhRow.text")}</p>
                </Col>
                <Col md={4}>
                  <img
                    src={scopri}
                    style={{ width: "284px" }}
                    alt="Curiosità"
                  />
                </Col>
              </Row>
            </Container>
            <Row className="gray-background" style={{ paddingTop: "70px" }}>
              <Col md={8} className="text-center content-center">
                <p className="text-bold title">
                  {t("pages.ar.eighthRow.title")}
                </p>
                <p>{t("pages.ar.eighthRow.text")}</p>
                <img
                  src={percorsi}
                  alt={t("pages.ar.eighthRow.title")}
                  style={{ width: "145px", margin: "30px 0px" }}
                />
              </Col>
            </Row>
            <Row className="gray-background ">
              <div className="percorsi-container content-center">
                <div className="percorsi green-background">
                  <img
                    src={arconte}
                    alt={t("pages.ar.eighthRow.firstBox")}
                    style={{ width: "375px" }}
                  />
                  <p className="text-bold">
                    {t("pages.ar.eighthRow.firstBox")}
                  </p>
                </div>
                <div className="percorsi red-background">
                  <img
                    src={arcavalieri}
                    alt={t("pages.ar.eighthRow.secondBox")}
                    style={{ width: "375px" }}
                  />
                  <p className="text-bold">
                    {t("pages.ar.eighthRow.secondBox")}
                  </p>
                </div>
                <div className="percorsi blue-background">
                  <img
                    src={arpescatori}
                    alt={t("pages.ar.eighthRow.thirdBox")}
                    style={{ width: "375px" }}
                  />
                  <p className="text-bold">
                    {t("pages.ar.eighthRow.thirdBox")}
                  </p>
                </div>
              </div>
            </Row>
            <Biglietto></Biglietto>
            <Footer />
          </Container>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Ar);
