// Core packages
import React from "react";

// Third party packages
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MagicSliderDots from "react-magic-slider-dots";
import "react-magic-slider-dots/dist/magic-dots.css";
import { connect } from "react-redux";
import { Col } from "react-bootstrap";

// Custom packages
import arrow from "../assets/icone/arrow-right.png";
import pinblue from "../assets/icone/percorso-blue.png";
import pingreen from "../assets/icone/percorso-green.png";
import pinred from "../assets/icone/percorso-red.png";

/**
 * Script start
 */
const SliderDot = (props) => {
  const { curr_lang, luoghi, t } = props;
  //const { curr_lang, t } = props;
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
    appendDots: (dots) => {
      return <MagicSliderDots dots={dots} numDotsToShow={3} dotWidth={30} />;
    },
  };

  const getImage = (element, index) => {
    switch (element.nome) {
      case "pescatore":
        return (
          <img
            alt={element.nome}
            key={element.nome + element.pos + index}
            src={pinblue}
          />
        );

      case "conte":
        return (
          <img
            alt={element.nome}
            key={element.nome + element.pos + index}
            src={pingreen}
          />
        );

      case "cavaliere":
        return (
          <img
            alt={element.nome}
            key={element.nome + element.pos + index}
            src={pinred}
          />
        );

      default:
        return <React.Fragment key={element.nome + element.pos + index} />;
    }
  };

  return (
    <Slider {...settings}>
      {luoghi.map((item) => (
        <div className="flex-slider" key={item.urw}>
          <Col md={6} className="no-padding">
            <img
              alt={item.urw}
              src={`${process.env.PUBLIC_URL}/assets/img/luoghi/${item.urw}/cover/${item.img}`}
              style={{ width: "100%" }}
            />
          </Col>
          <Col md={6} className="black-background no-padding">
            <p className="text-italic text-left">{t("general.places")}</p>
            <p className="slider_name">{item.tappa}</p>
            <p className="pin">
              {item.percorso.map((element, index) => getImage(element, index))}
            </p>
            <p className="arrow">
              <a href={`/${curr_lang}/luogo/${item.urw}`}>
                <img src={arrow} alt="Freccia" style={{ width: "49px" }} />
              </a>
            </p>
          </Col>
        </div>
      ))}
    </Slider>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(SliderDot);
