// Core packages
import React from "react";

// Third party packages
import { connect } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";

// Custom packages
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import plinio from "../assets/borgo/plinio_giovane.jpg";
import header from "../assets/header/il-borgo.jpg";
import borgo from "../assets/borgo/borgo.jpg";
import epifania from "../assets/borgo/epifania.jpg";
import Biglietto from "../components/Biglietto";
import SliderDot from "../components/SliderDot";
import pin from "../assets/icone/icon-pin.png";

import slider1 from "../assets/borgo/slider/il-borgo-gallery-1-moris-lorenzi.jpg";
import slider2 from "../assets/borgo/slider/il-borgo-gallery-2-moris-lorenzi.jpg";
import slider3 from "../assets/borgo/slider/il-borgo-gallery-3-morelli-remigio.jpg";
import slider4 from "../assets/borgo/slider/il-borgo-gallery-4--Alberto-Locatelli.jpg";
import slider5 from "../assets/borgo/slider/il-borgo-gallery-5-moris-lorenzi.jpg";
import slider6 from "../assets/borgo/slider/il-borgo-gallery-6-moris-lorenzi.jpg";

/**
 * Script start
 */
const Borgo = (props) => {
  const { curr_lang, t } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.borgo.meta.title")}</title>
        <meta name="description" content={t("pages.borgo.meta.description")} />
      </Helmet>
      <Container fluid>
        <Menu></Menu>
        <Row>
          <Col md={12} className="header">
            <img src={header} alt="Borgo Corenno Plinio" />
          </Col>
        </Row>
        <Row className="ar-row">
          <Col md={8} className="text-center content-center">
            <p className="text-italic italic-title">
              {t("pages.borgo.firstRow.pretitle")}
            </p>
            <h1 className="text-bold">{t("pages.borgo.firstRow.title")}</h1>
            <p>{t("pages.borgo.firstRow.text")}</p>
          </Col>
        </Row>
        <Row className="gray-background ">
          <Container>
            <Row className="text-left padding-row order-row">
              <Col md={6}>
                <p
                  className="text-italic italic-title"
                  style={{ color: "#000000" }}
                >
                  {t("pages.borgo.secondRow.pretitle")}
                </p>
                <p className="text-bold title">
                  {t("pages.borgo.secondRow.title")}
                </p>
                <p className="text-color">{t("pages.borgo.secondRow.text")}</p>
              </Col>
              <Col md={6}>
                <img
                  src={borgo}
                  alt="Il bordo dei mille gradini"
                  style={{ maxWidth: "720px" }}
                  className="img-over"
                />
              </Col>
            </Row>
          </Container>
        </Row>

        <Container className="padding-row text-left">
          <Row className="ar-row">
            <Col md={6}>
              <img
                src={plinio}
                alt="Ritratto Plinio"
                style={{ width: "410px", maxWidth: "100%" }}
              />
            </Col>
            <Col md={6} style={{ marginTop: "100px" }}>
              <p
                className="text-italic italic-title"
                style={{ color: "#000000" }}
              >
                {t("pages.borgo.thirdRow.title")}
              </p>
              <br />
              <p className="text-color">{t("pages.borgo.thirdRow.text")}</p>
            </Col>
          </Row>
          <Row className="ar-row order-row">
            <Col md={6}>
              <p
                className="text-italic italic-title"
                style={{ color: "#000000" }}
              >
                {t("pages.borgo.fourthRow.title")}
              </p>
              <br />
              <p className="text-color">{t("pages.borgo.fourthRow.text")}</p>
            </Col>
            <Col md={6}>
              <img
                src={epifania}
                alt="Storia della comunità"
                style={{ maxWidth: "100%" }}
              />
            </Col>
          </Row>
          <Row className="text-center text-italic boxed-info ar-row">
            <Col md={8} className="content-center">
              {t("pages.borgo.fifthRow.title")}
            </Col>
          </Row>
          <Row className="mansory-row-one">
            <Col>
              <img src={slider1} />
            </Col>
            <Col>
              <img src={slider2} />
            </Col>
            <Col>
              <img src={slider3} />
            </Col>
          </Row>
          <Row className="mansory-row-two">
            <Col>
              <img src={slider4} />
            </Col>
            <Col>
              <img src={slider5} />
            </Col>
            <Col>
              <img src={slider6} />
            </Col>
          </Row>
        </Container>

        <Row className="slider-row">
          <Col md={12} className="no-padding">
            <SliderDot />
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center">
            <img src={pin} alt="Pin" style={{ width: "12px" }} />
            <a className="text-link" href={`${curr_lang}/luoghi`}>
              {t("pages.borgo.sixthRow.discoverAllPlaces")}
            </a>
          </Col>
        </Row>
        <Biglietto></Biglietto>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Borgo);
