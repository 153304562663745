// Core packages
import React, { useEffect, useState } from "react";

// Third party packages
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import arrow from "../assets/icone/arrow-right.png";
import pinblue from "../assets/icone/percorso-blue.png";
import pingreen from "../assets/icone/percorso-green.png";
import pinred from "../assets/icone/percorso-red.png";

/**
 * Script start
 */
const Portfolio = (props) => {
  const { luoghi, curr_lang, t } = props;
  const defaultFiltri = {
    tutti: true,
    cavaliere: false,
    conte: false,
    pescatore: false,
    fuori: false,
  };
  const [filtri, setFiltri] = useState(defaultFiltri);
  const [schede, setSchede] = useState(luoghi);

  useEffect(() => {
    const id = window.location.pathname.split("/luoghi/")[1];
    if (!id) {
      setFiltri({ ...filtri, tutti: true });
    } else {
      switch (id) {
        case "conte":
          setFiltri({ ...filtri, conte: true, tutti: false });
          break;
        case "fuori-percorso":
          setFiltri({ ...filtri, fuori: true, tutti: false });
          break;
        case "cavaliere":
          setFiltri({ ...filtri, cavaliere: true, tutti: false });
          break;
        case "pescatore":
          setFiltri({ ...filtri, pescatore: true, tutti: false });
          break;
        default:
          setFiltri({ ...filtri, tutti: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get active filter keys
   */
  const getActiveFiltersKeys = () => {
    const filterKeys = Object.keys(filtri);
    const items = Object.values(filtri)
      ?.map((filter, index) => (filter ? filterKeys[index] : undefined))
      .filter((el) => el);

    if (items.length === 0) {
      items.push("tutti");
    }

    return items;
  };

  const loadSchede = () => {
    const activeFilters = getActiveFiltersKeys();
    const filtered = schede.map((p) => ({
      ...p,
      filtered: p.percorso.some((r) => activeFilters.includes(r.nome)),
    }));
    setSchede(filtered);
  };

  useEffect(() => {
    loadSchede();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtri]);

  return (
    <div className="portfolio">
      <div className="portfolio__labels">
        <span className={filtri.conte ? "actived" : ""}>
          <span
            onClick={() => setFiltri({ ...filtri, conte: true, tutti: false })}
          >
            {t("components.portfolio.filters.1")}
          </span>
          <span
            className={filtri.conte ? "x_item" : "filter-out"}
            onClick={() => setFiltri({ ...filtri, conte: false })}
          >
            &#10006;
          </span>
        </span>
        <span className={filtri.cavaliere ? "actived" : ""}>
          <span
            onClick={() =>
              setFiltri({ ...filtri, cavaliere: true, tutti: false })
            }
          >
            {t("components.portfolio.filters.2")}
          </span>
          <span
            className={filtri.cavaliere ? "x_item" : "filter-out"}
            onClick={() => setFiltri({ ...filtri, cavaliere: false })}
          >
            &#10006;
          </span>
        </span>
        <span className={filtri.pescatore ? "actived" : ""}>
          <span
            href="#"
            onClick={() =>
              setFiltri({ ...filtri, pescatore: true, tutti: false })
            }
          >
            {t("components.portfolio.filters.3")}
          </span>
          <span
            className={filtri.pescatore ? "x_item" : "filter-out"}
            onClick={() => setFiltri({ ...filtri, pescatore: false })}
          >
            &#10006;
          </span>
        </span>
        <span className={filtri.fuori ? "actived" : ""}>
          <span
            onClick={() => setFiltri({ ...filtri, fuori: true, tutti: false })}
          >
            {t("components.portfolio.filters.4")}
          </span>
          <span
            className={filtri.fuori ? "x_item" : "filter-out"}
            onClick={() => setFiltri({ ...filtri, fuori: false })}
          >
            &#10006;
          </span>
        </span>
      </div>
      <Row className="portfolio__container">
        {schede.map(
          (item) =>
            item.filtered === true && (
              <Col
                md={3}
                className="portfolio_figure"
                onMouseEnter={(e) => {
                  e.stopPropagation();
                  e.target.classList.add("show");
                }}
                onMouseLeave={(e) => {
                  e.stopPropagation();
                  e.target.classList.remove("show");
                }}
                key={item.tappa}
                style={{
                  backgroundImage:
                    "url(" +
                    process.env.PUBLIC_URL +
                    "'/assets/img/luoghi/" +
                    item.urw +
                    "/cover/" +
                    item.img +
                    "')",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <p className="text-italic text-left">
                  {t("components.portfolio.places")}
                </p>
                <p className="portfolio_figure_name">{item.tappa}</p>
                <p className="pin">
                  {item.percorso.map((element) => {
                    switch (element.nome) {
                      case "pescatore":
                        return (
                          <img
                            alt={element.nome}
                            key={element.nome + element.pos}
                            src={pinblue}
                          />
                        );

                      case "conte":
                        return (
                          <img
                            alt={element.nome}
                            key={element.nome + element.pos}
                            src={pingreen}
                          />
                        );

                      case "cavaliere":
                        return (
                          <img
                            alt={element.nome}
                            key={element.nome + element.pos}
                            src={pinred}
                          />
                        );

                      default:
                        return <React.Fragment />;
                    }
                  })}
                </p>
                <p className="arrow">
                  <a href={`/${curr_lang}/luogo/${item.urw}`}>
                    <img alt="Freccia" src={arrow} style={{ width: "49px" }} />
                  </a>
                </p>
              </Col>
            )
        )}
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Portfolio);
