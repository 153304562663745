// Core packages
import React from "react";
import ReactDOM from "react-dom";

// Third party packages
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// Custom packages
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./store/Persistor";

/**
 * Script start
 */
// require("aframe");
require("@ar-js-org/ar.js");
require("aframe-look-at-component");
require("aframe-extras");

const { persistor, store } = configureStore();
persistor.purge();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
serviceWorker.unregister();
