// Core packages
import React from "react";

// Third party packages
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import Menu from "../components/Menu";
import Footer from "../components/Footer";

/**
 * Script start
 */
const NoteLegali = (props) => {
  const { curr_lang, t } = props;
  return (
    <React.Fragment>
      <Container fluid className="info-page">
        <Menu></Menu>
        <Row>
          <Col md={10} className="mt-5 mb-5 content-center">
            {/* <p className="text-italic italic-title">
              Informativa sul trattamento dei dati personali
            </p> */}
            <h1 className="text-bold">
              {t("pages.note-legali.firstRow.title")}
            </h1>
            <p
              dangerouslySetInnerHTML={{
                __html: t("pages.note-legali.firstRow.text"),
              }}
            />
          </Col>
        </Row>
        <Footer />
      </Container>
    </React.Fragment>
  );
};
const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(NoteLegali);
