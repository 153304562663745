// Core packages
import React, { useCallback, useEffect, useState } from "react";

// Third party packages
import { useSwipeable } from "react-swipeable";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Custom packages
import Content from "./Content";
import catIt from "../../data/it/categorie.json";
import catEn from "../../data/en/categorie.json";
import luoghiIt from "../../data/it/luoghi.json";
import luoghiEn from "../../data/en/luoghi.json";

/**
 * Script start
 */
const Anteprima = (props) => {
  const { luogoid, close, open, distance, defaultStatus, curr_lang } = props;
  const [status, setStatus] = useState(defaultStatus || "preview");
  const [luogo, setLuogo] = useState({});
  const [categories, setCategories] = useState([]);
  const [cat, setCat] = useState(curr_lang === "en" ? catEn : catIt);
  const [luoghi, setLuoghi] = useState(
    curr_lang === "en" ? luoghiEn : luoghiIt
  );
  const navigate = useNavigate();

  /**
   * Populate categories state with an array of images
   */
  const getCategories = useCallback(
    (luogo) => {
      const elements = [];
      let i = 0;
      if (!Object.keys(luogo).length) {
        return <React.Fragment />;
      }

      console.log("luogo", luogo);
      for (const category of cat) {
        for (const percorso of luogo.percorso) {
          if (category.slug === percorso.nome) {
            elements.push(
              <span key={i} style={{ color: category.color }}>
                <img
                  alt={percorso.nome}
                  className="icon-info"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/categorie/" +
                    category.path +
                    "icona/" +
                    category.img
                  }
                />
              </span>
            );
          }
          i++;
        }
      }

      setCategories(elements);
    },
    [cat]
  );

  useEffect(() => {
    if (curr_lang === "en") {
      setCat(catEn);
      setLuoghi(luoghiEn);
    } else {
      setCat(catIt);
      setLuoghi(luoghiIt);
    }
  }, [curr_lang, getCategories]);

  const swipeUp = () => {
    setStatus("content");
    open();
  };
  const swipeDown = () => {
    setStatus("preview");
    close();
  };
  const handlers = useSwipeable({
    onSwipedUp: () => swipeUp(),
    onSwipedDown: () => swipeDown(),
  });

  const closeLuogoContent = () => {
    setLuogo({});
    close();

    if (defaultStatus === "content") {
      navigate(`/${curr_lang}/ar-app`);
    }
  };

  useEffect(() => {
    const currentLuogo = luoghi.find(
      (luogo) => luogo.id?.toString() === luogoid?.toString()
    );
    setLuogo(currentLuogo);
    getCategories(currentLuogo);
  }, []);

  return (
    <React.Fragment>
      {status === "content" && (
        <React.Fragment>
          <Content luogo={luogo} closeFn={() => closeLuogoContent()}></Content>
        </React.Fragment>
      )}
      {status === "preview" && (
        <React.Fragment>
          <div
            {...handlers}
            style={{
              width: "100%",
              background: "white",
              height: 100,
              position: "fixed",
              bottom: 0,
            }}
            key={luogo.id}
          >
            <div id="anteprima">
              <div className="swipe">
                <p className="swipe-icon"></p>
              </div>
              <div id="content-anteprima" onClick={() => swipeUp()}>
                <div className="image-anteprima">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/luoghi/" +
                      luogo.urw +
                      "/cover/" +
                      luogo.img
                    }
                    alt={luogo.nome}
                    style={{ width: "140px" }}
                  />
                </div>
                <div className="descr-anteprima">
                  <p className="">
                    {categories}

                    {distance > 0 &&
                      !Number.isNaN(parseInt(distance.toString())) && (
                        <span className="text-bold distance">{distance} m</span>
                      )}
                  </p>
                  <p className="text-bold size-16">{luogo.tappa}</p>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Anteprima);
