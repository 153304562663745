// Core packages
import React from "react";

// Third party packages
import { Row, Col, Container } from "react-bootstrap";

// Custom packages
import logo from "../assets/logo-comune.jpg";
import azero from "../assets/icone/azero-logo.png";
import wip from "../assets/icone/wip.png";

/**
 * Script start
 */
const Footer = (props) => {
  return (
    <Row className="footer-c ">
      <Container>
        <Row>
          <Col
            md={3}
            className="text-left"
            style={{ marginTop: "35px", lineHeight: "16px" }}
          >
            <img
              src={wip}
              style={{ width: "23px" }}
              className="wip"
              alt="Work in progress"
            />
            <p className="text-bold">Sito in costruzione</p>
            <p className="text-italic-semi">Coming Soon</p>
          </Col>
          <Col md={6} style={{ marginTop: "15px" }} className="logo">
            <img src={logo} style={{ width: "60px" }} alt="Comune di Dervio" />
            <p className="text-bold text-center">Comune di Dervio</p>
          </Col>
          <Col md={3} style={{ marginTop: "35px" }}>
            <p className="info text-right">
              © {new Date().getFullYear()} Comune di Dervio. <br />
              Tutti i diritti riservati.
              <br />
              <a target="_blank" rel="noreferrer" href="https://www.azero.it">
                Made by{" "}
                <img src={azero} alt="Azero" style={{ width: "16px" }} />
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};
export default Footer;
