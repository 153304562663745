// Core packages
import React from "react";
import { Container, Row, Col } from "react-bootstrap";

// Third party packages
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

// Custom packages
import Menu from "../components/Menu";
import Mappa from "../components/Mappa";
import Footer from "../components/Footer";
import Form from "../components/Form";
import header from "../assets/header/contatti.jpg";
import proloco from "../assets/loghi/logo-proloco.png";
import logo from "../assets/logo-comune.jpg";

/**
 * Script start
 */
const Contatti = (props) => {
  const { t } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.contatti.meta.title")}</title>
        <meta
          name="description"
          content={t("pages.contatti.meta.description")}
        />
      </Helmet>
      <Container fluid className="info-page">
        <Menu></Menu>
        <Row>
          <Col md={12} className="header">
            <img src={header} alt="Contatti Corenno Plinio" />
          </Col>
        </Row>
        <Row>
          <Col md={8} className="text-center content-center">
            <p className="text-italic italic-title">
              {t("pages.contatti.firstRow.pretitle")}
            </p>
            <h1 className="text-bold">{t("pages.contatti.firstRow.title")}</h1>
            <p> {t("pages.contatti.firstRow.text")}</p>
          </Col>
        </Row>
        <div className="footer-c row">
          <Row style={{ width: "400px" }} className="content-center">
            <Col md={6}>
              <div style={{ lineHeight: "150px" }}>
                <img
                  src={logo}
                  style={{ width: "88px" }}
                  alt="Comune di Dervio"
                />
              </div>
              <p className="text-center text-bold"> Comune di Dervio </p>
              <p className="text-center">
                P.zza IV novembre
                <br />
                23824 Dervio (LC)
                <br />T <a href="tel:+390341806411">+39 0341 806411</a>
                <br />F +39 0341 806444
              </p>
            </Col>
            <Col md={6}>
              <div style={{ lineHeight: "150px" }}>
                <img
                  src={proloco}
                  style={{ width: "65px" }}
                  alt="Pro Loco Dervio"
                />
              </div>
              <p className="text-center text-bold">Pro Loco Dervio</p>
              <p className="text-center">
                Via Martiri della Liberazione, 6<br />
                23824 Dervio (LC)
                <br />T <a href="tel:+390341804450">+39 0341 804450</a>
                <br />E{" "}
                <a href="mailto:proloco.dervio@tin.it">proloco.dervio@tin.it</a>
              </p>
            </Col>
          </Row>
        </div>
        <Row>
          <Col md={8} className="text-box text-left">
            <p className="text-bold" style={{ fontSize: "24px" }}>
              {t("pages.contatti.form.title")}
            </p>
            <p className="text-color">{t("pages.contatti.form.text")}</p>
            <Form></Form>
          </Col>
          <Col md={4}>
            <Mappa></Mappa>
          </Col>
        </Row>
        <Footer />
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Contatti);
