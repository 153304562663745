// Core packages
import React, { useEffect, useState } from "react";

// Third party packages
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import aframe from "aframe";

// Custom packages
import target from "../assets/icone/target2x.png";
import { setARStatus } from "../store/actions";
import Anteprima from "../components/AR/Anteprima";
import Filtro from "../components/Filtro";
import luoghiIt from "../data/it/luoghi.json";
import luoghiEn from "../data/en/luoghi.json";

/**
 * Script start
 */

const ArApp = (props) => {
  const { t, curr_lang } = props;
  const [luoghi, setLuoghi] = useState(
    curr_lang === "en" ? luoghiEn : luoghiIt
  );
  const debug = true;
  const [poiItems, setPoiItems] = useState(luoghi);
  const [poiItemsInDOM, setPoiItemsInDOM] = useState([]);
  const [previewLuogoId, setPreviewLuogoId] = useState("");
  const [previewLuogoDistance, setPreviewLuogoDistance] = useState("");
  const [userLatitude, setUserLatitude] = useState("");
  const [userLongitude, setUserLongitude] = useState("");
  const [previewDefaultStatus, setPreviewDefaultStatus] = useState("");

  useEffect(() => {
    loadPlaceId();
    setARStatus(true);
    initListener();
    return () => {
      setARStatus(false);
    };
  });

  useEffect(() => {
    console.log("curr_lang", curr_lang);

    if (curr_lang === "en") {
      setLuoghi(luoghiEn);
      setPoiItems(luoghiEn);
    } else {
      setLuoghi(luoghiIt);
      setPoiItems(luoghiIt);
    }
  }, [curr_lang]);

  useEffect(() => {
    if (curr_lang === "en") {
      setLuoghi(luoghiEn);
      setPoiItems(luoghiEn);
    } else {
      setLuoghi(luoghiIt);
      setPoiItems(luoghiIt);
    }
  }, [curr_lang]);

  useEffect(() => {
    // Make visible/invisible POIs not included in fildered categories
    const { filtri_attivi } = props;
    // console.log("poiItemsInDOM", poiItemsInDOM);
    for (const poi of poiItemsInDOM) {
      // console.log("poi", poi);
      const categoriesAtr = poi.getAttribute("categories");
      if (categoriesAtr) {
        const categories = categoriesAtr.split(",");
        const poiInCategories = filtri_attivi.some(
          (filtro) => categories.includes(filtro.nome) && filtro.stato
        );
        // console.log("poiInCategories", poiInCategories);
        if (poiInCategories) {
          poi.object3D.visible = true;
        } else {
          poi.object3D.visible = false;
        }
      }
    }

    console.log("props", props);
  }, [props]);

  /**
   * Load placeId if provided
   */
  const loadPlaceId = () => {
    try {
      const id = window.location.pathname.split("/ar-app/")[1];
      if (id) {
        const currentPlace = luoghi.find(
          (el) => el.id?.toString() === id.toString()
        );
        setPreviewDefaultStatus("content");
        setPreviewLuogoId(id);
        console.log("currentPlace", currentPlace);
      }
    } catch (error) {
      console.error("error: ", error);
    }
  };

  /**
   * Update poi items distance
   */
  const updatePoiItemsDistance = () => {
    const items = document.querySelectorAll("[gps-entity-place]");
    setPoiItemsInDOM(items);
    if (!items.length) {
      return;
    }
    for (const poiInDOM of items) {
      const rawDistance = poiInDOM.getAttribute("distance");
      const distance = Number.isNaN(rawDistance) ? "NA" : rawDistance;
      poiInDOM.setAttribute("distanceText", `Distanza: ${distance}`);
    }
    // console.log("poiItemsInDOM", items);
  };

  const initListener = () => {
    // Update state every time user's position is updated from native APIs
    navigator.geolocation.getCurrentPosition(function (position) {
      setUserLatitude(position?.coords?.latitude);
      setUserLongitude(position?.coords?.longitude);
    });

    window.addEventListener("gps-camera-update-position", (e) => {
      updatePoiItemsDistance();
    });

    const scaleCmponentAlreadyRegistered = Object.keys(
      aframe?.components
    )?.includes("scale-on-mouseenter");
    if (!scaleCmponentAlreadyRegistered) {
      aframe.registerComponent("scale-on-mouseenter", {
        schema: {
          to: {
            default: "2.5 2.5 2.5",
            type: "vec3",
          },
        },

        init: function () {
          this.el.addEventListener("fusing", function (event) {
            // console.group();
            const { target } = event;

            // Ok so poi is not in preview mode. We have to open
            // the preview. Let's get poi ID
            const luogoId = target.getAttribute("luogoId");
            if (!luogoId) {
              // console.groupEnd();
              return;
            }

            // Check if item is visible
            if (!target?.object3D?.visible) {
              // console.groupEnd();
              return;
            }

            const distance = target.getAttribute("distance");

            // console.log("event", event);
            // console.log("target", target);
            // console.log("luogoId", luogoId);
            // console.log("distance", distance);
            // console.log("previewLuogoId", previewLuogoId);

            // We only update state if new luogoId is different
            if (luogoId !== previewLuogoId) {
              setPreviewLuogoDistance(parseInt(distance));
              setPreviewLuogoId(luogoId);
              // console.log("previewLuogoId", luogoId);
            }
            // console.groupEnd();
          });
        },
      });
    }
  };

  const onPreviewClose = () => {
    setPreviewLuogoId("");
    setPreviewLuogoDistance("");
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{t("pages.ar.meta.title")}</title>
        <meta name="description" content={t("pages.ar.meta.description")} />
      </Helmet>
      <div className="ar-app-wrapper">
        <a-scene
          cursor="rayOrigin: mouse; fuse: true; fuseTimeout: 0;"
          raycaster="objects: [gps-entity-place];"
          vr-mode-ui="enabled: false"
          embedded
          arjs={`sourceType: webcam; patternRatio: 1; sourceWidth:1280; sourceHeight:960; displayWidth: 1280; displayHeight: 960; debugUIEnabled: ${
            debug ? "true" : "false"
          };`}
        >
          <a-assets>
            {poiItems.map((poiItem) => (
              <img
                key={`poi-${poiItem?.id}`}
                id={`poi-${poiItem?.id}`}
                crossOrigin="anonymous"
                src={`${process.env.PUBLIC_URL}/assets/img/luoghi/${poiItem?.urw}/pin/${poiItem.pin}`}
                alt={poiItem.tappa}
              />
            ))}
          </a-assets>
          {poiItems.map((poiItem) => {
            var categorie = [];
            Object.keys(poiItem.percorso).forEach((key) => {
              categorie.push(poiItem.percorso[key].nome);
            });
            return (
              <a-image
                key={poiItem?.id}
                scale-on-mouseenter
                src={`#poi-${poiItem.id}`}
                position="0 0 -5"
                rotation="0 0 0"
                scale="1 1 1"
                look-at="[gps-camera]"
                luogoId={poiItem?.id}
                width="1"
                height="1"
                categories={categorie}
                lat={poiItem?.pos?.lat}
                lng={poiItem?.pos?.lng}
                gps-entity-place={`latitude: ${poiItem?.pos?.lat}; longitude: ${poiItem?.pos?.lng};`}
                // animation="property: object3D.position.y; to: 1.01; dir: alternate; dur: 2000; loop: true"
              />
            );
          })}
          <a-camera gps-camera="minDistance: 10;" rotation-reader style={{}}>
            <a-image
              id="cursore"
              animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
              animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
              animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
              cursor="fuse: true;"
              position="0 0 -1"
              scale="1 1 1"
              // width="66"
              // height="50"
              width="0.17"
              height="0.1"
              // style={{
              //   // width: 50,
              //   // height: 50,
              //   // width: 66.66666,
              //   // height: 50,
              // }}
              material="transparent:false;alphaTest: 0.5;"
              src={target}
            />
          </a-camera>
        </a-scene>
        {/* <a-scene
          vr-mode-ui="enabled: false"
          embedded
          arjs={`sourceType: webcam; videoTexture: true; debugUIEnabled: ${
            debug ? "true" : "false"
          };`}
        >
          <a-assets>
            {poiItems.map((poiItem) => (
              <img
                key={`poi-${poiItem?.id}`}
                id={`poi-${poiItem?.id}`}
                crossOrigin="anonymous"
                src={`${process.env.PUBLIC_URL}/assets/img/luoghi/${poiItem?.urw}/pin/${poiItem.pin}`}
                alt={poiItem.tappa}
              />
            ))}
          </a-assets>
          {poiItems.map((poiItem) => {
            var categorie = [];
            Object.keys(poiItem.percorso).forEach((key) => {
              categorie.push(poiItem.percorso[key].nome);
            });
            return (
              <a-image
                key={poiItem?.id}
                scale-on-mouseenter
                src={`#poi-${poiItem.id}`}
                position="0 0 -5"
                rotation="0 0 0"
                scale="1 1 1"
                look-at="[gps-camera]"
                luogoId={poiItem?.id}
                width="1"
                height="1"
                categories={categorie}
                lat={poiItem?.pos?.lat}
                lng={poiItem?.pos?.lng}
                gps-entity-place={`latitude: ${poiItem?.pos?.lat}; longitude: ${poiItem?.pos?.lng};`}
                // animation="property: object3D.position.y; to: 1.01; dir: alternate; dur: 2000; loop: true"
              />
            );
          })}

          <a-camera
            // gps-camera="maxDistance: 80; gpsMinDistance: 1; gpsMinDistance: 5; gpsTimeInterval: 5000"
            gps-camera
            arjs-look-controls="smoothingFactor: 0.1"
            rotation-reader
          >
            <a-image
              id="cursore"
              animation__click="property: scale; startEvents: click; easing: easeInCubic; dur: 150; from: 0.1 0.1 0.1; to: 1 1 1"
              animation__fusing="property: scale; startEvents: fusing; easing: easeInCubic; dur: 1500; from: 1 1 1; to: 0.1 0.1 0.1"
              animation__mouseleave="property: scale; startEvents: mouseleave; easing: easeInCubic; dur: 500; to: 1 1 1"
              cursor="fuse: true;"
              position="0 0 -1"
              scale="1 1 1"
              width="0.1"
              height="0.1"
              material="transparent:false;alphaTest: 0.5;"
              src={target}
            />
          </a-camera>
        </a-scene> */}

        {previewLuogoId ? (
          <Anteprima
            close={() => onPreviewClose()}
            open={() => null}
            luogoid={previewLuogoId}
            distance={previewLuogoDistance}
            defaultStatus={previewDefaultStatus || ""}
          />
        ) : (
          <React.Fragment>
            <Filtro />
            {debug && (
              <div
                style={{
                  position: "absolute",
                  right: 5,
                  bottom: 15,
                  fontSize: 10,
                }}
              >
                Lat: {userLatitude} <br />
                Lng: {userLongitude}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});
const mapDispatchToProps = (dispatch) => ({
  setARStatus: (newStatus) => dispatch(setARStatus(newStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArApp);
