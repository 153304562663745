// Core packages
import React from "react";

// Third party packages
import { Row, Col } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import mappa from "../assets/mappa-percorsi.jpg";
import logoblack from "../assets/icone/percorso-black.png";

/**
 * Script start
 */
const BarPercorsi = (props) => {
  const { curr_lang, t } = props;

  return (
    <Row className="gray-background">
      <Col md={6}>
        <img src={mappa} alt="Mappa percorsi" style={{ width: "100%" }} />
      </Col>
      <Col className="text-box text-left" md={6}>
        <img src={logoblack} alt="Pin" style={{ width: "19px" }} />
        <p className="text-italic italic-title">
          {t("components.barPercorsi.pretitle")}
        </p>
        <p className="text-bold" style={{ fontSize: "42px" }}>
          {t("components.barPercorsi.title")}
        </p>
        <p>{t("components.barPercorsi.paragraph")}</p>
        <br />
        <p>
          <a className="text-link" href={`/${curr_lang}/percorsi-tematici`}>
            {t("components.barPercorsi.cta")}
          </a>
        </p>
        <p className="link-fuori">
          <a className="text-link" href={`/${curr_lang}/luoghi/fuori-percorso`}>
            {t("components.barPercorsi.cta2")}
          </a>
        </p>
      </Col>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(BarPercorsi);
