// Core packages
import React from "react";

// Third party packages
import { Row, Col, Button, Container } from "react-bootstrap";
import { connect } from "react-redux";

// Custom packages
import logo from "../assets/logo-comune.jpg";
import iconinfo from "../assets/icone/icon-info.png";

/**
 * Script start
 */
const Footer = (props) => {
  const { curr_lang, t } = props;

  return (
    <Row className="footer-c text-left">
      <Container>
        <Row>
          <Col md={6}>
            <Row>
              <div>
                <img
                  src={logo}
                  alt="Comune di Dervio"
                  style={{ width: "124px" }}
                />
              </div>
              <Col md={8} className="info-desc">
                <p className="text-bold">Comune di Dervio</p>
                <p>
                  <a href="https://dervio.org" target="_blank" rel="noreferrer">
                    dervio.org
                  </a>
                </p>
                <br />
                <p className="info">
                  <a href={`/${curr_lang}/note-legali`}>
                    {t("components.footer.cta2")}
                  </a>{" "}
                  &nbsp;&nbsp;|&nbsp;&nbsp;{" "}
                  <a href={`/${curr_lang}/privacy-policy`}>Privacy</a>
                </p>
                <p className="info">
                  {new Date().getFullYear() +
                    t("components.footer.allRightsReserved")}
                </p>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="text-left info-box">
            <img
              src={iconinfo}
              alt="Informazioni utili su Corenno Plinio"
              style={{ width: "21px" }}
            />
            <p>{t("components.footer.textInfoUtili")}</p>
            <a href={`/${curr_lang}/info`}>
              <Button variant="primary transparent text-medium" size="lg">
                {t("components.footer.cta")}
              </Button>
            </a>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(Footer);
