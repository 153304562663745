// Core packages
import React, { useState } from "react";

// Third party packages
import { connect } from "react-redux";
import { Col, Button, Form } from "react-bootstrap";
import emailjs from "emailjs-com";

// Custom packages

/**
 * Script start
 */
const FormComponent = (props) => {
  const { curr_lang, t } = props;
  const [validated, setValidated] = useState(false);

  /**
   * Handle form submit
   *
   * @param Event event The submit event
   *
   * @return void
   */
  const handleSubmit = async (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    setValidated(true);
    event.preventDefault();

    // Send email
    const result = await emailjs.sendForm(
      "gmail-test-azero-corenno",
      "template_7GqJK5gi",
      event.target,
      "user_5LEnWlT7TWs4pHBk4vxxc"
    );
    console.log("result", result);
  };

  return (
    <Form
      noValidate
      validated={validated}
      className="contact-form"
      onSubmit={handleSubmit}
    >
      <div className="row">
        <Form.Group as={Col} md="6" controlId="validationCustom01">
          <Form.Control
            required
            type="text"
            placeholder={t("components.form.fields.nome.placeholder")}
            name={t("components.form.fields.nome.name")}
          />
          <Form.Control.Feedback type="invalid">
            {t("components.form.required")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom02">
          <Form.Control
            required
            type="text"
            placeholder={t("components.form.fields.cognome.placeholder")}
            name={t("components.form.fields.cognome.name")}
          />
          <Form.Control.Feedback type="invalid">
            {t("components.form.required")}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
      <div className="row mt-3">
        <Form.Group as={Col} md="6" controlId="validationCustom03">
          <Form.Control
            type="email"
            name={t("components.form.fields.email.name")}
            placeholder={t("components.form.fields.email.placeholder")}
            required
          />
          <Form.Control.Feedback type="invalid">
            {t("components.form.required")}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6" controlId="validationCustom04">
          <Form.Control
            type="text"
            placeholder={t("components.form.fields.telefono.placeholder")}
            name={t("components.form.fields.telefono.name")}
          />
        </Form.Group>
      </div>
      <div className="row mt-3 mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom05">
          <Form.Control
            as="textarea"
            rows="3"
            name={t("components.form.fields.messaggio.name")}
            placeholder={t("components.form.fields.messaggio.placeholder")}
          />
        </Form.Group>
      </div>

      <Form.Group>
        <Form.Check required inline feedback={t("components.form.required")} />
        <span>
          {t("components.form.iAccept")}{" "}
          <a href={`/${curr_lang}/privacy-policy`}>
            {t("components.form.privacyPolicy")}
          </a>
        </span>
      </Form.Group>
      <Button
        type="submit"
        className="btn mt-3 btn-primary transparent text-medium btn-lg"
      >
        {t("components.form.cta")}
      </Button>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(FormComponent);
